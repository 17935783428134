









































import { Component, Vue, Watch } from 'vue-property-decorator';
import { searchHelper } from '@/utils/search';
import { scrollToTop } from '@/utils/scroll';
import { includes, has, pull, cloneDeep, clone } from 'lodash';
import { db, didNotifications } from '@/firebase';

@Component
export default class LearningFeatureBuilder extends Vue {
  loading = false;
  messages: any[] = [];
  showArchived = false;

  quickAdd = {
    title: '',
    message: '',
    link: '',
  };

  rules = {
    link: [
      {
        validator: (rule: any, value: any, callback: any) => {
          if (value.length) {
            if (!value.startsWith('https://')) {
              callback(new Error('All links must start with https://'));
            }
          }
          callback();
        },
        trigger: 'blur',
      },
    ],
  };

  showState(status: string) {
    let type = '';
    switch (status) {
      case 'tested':
        type = 'success';
        break;
      case 'sent':
        type = 'info';
    }
    return type;
  }

  quickAddSave() {
    //
  }

  loadMessages() {
    this.loading = true;
    this.messages = [];
    if (this.showArchived) {
      didNotifications
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            this.messages.push({ id: doc.id, ...doc.data() });
          });
        })
        .then(() => (this.loading = false));
    } else {
      didNotifications
        .where('visible', '==', true)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            this.messages.push({ id: doc.id, ...doc.data() });
          });
        })
        .then(() => (this.loading = false));
    }
  }

  async created() {
    this.loadMessages();
  }

  destroyed() {
    //
  }
}
